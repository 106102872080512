import { Injectable } from '@angular/core';
import { DmagStateService } from './dmag-state.service';

/**
 * Servizio per la gestione dei permessi dell'utente loggato
 *
 * @export
 * @class DmagPermissionService
 */
@Injectable({
  providedIn: 'root',
})
export class DmagPermissionService {
  constructor(private stateService: DmagStateService) {}

  // TODO eseguire chiamata HTTP per cercare i permessi tenere conto che la chiamata è async
  /**
   * Controlla se l'utente ho determinati permessi.
   *
   * @param {string[]} checkPermission
   * @param {boolean} [requiredAll=true]
   * @returns {boolean}
   * @memberof DmagPermissionService
   */
  isGranted(checkPermission: string | string[], requiredAll = true): boolean {
    // Converte in array
    if (typeof checkPermission === 'string') {
      checkPermission = [checkPermission];
    }

    if (!checkPermission || checkPermission.length === 0) {
      return false;
    }

    // Controlla di essere loggati
    const profilo = this.stateService.state.profilo;
    if (!profilo) {
      return false;
    }

    // ---- Controlla i permessi
    const myPermissions = profilo.permessi;

    // Controlla che ci siano TUTTI i permessi (AND)
    if (requiredAll) {
      return checkPermission.every((value) => {
        return myPermissions.indexOf(value) >= 0;
      });
    }

    // Controlla che ci sia ALMENO un permesso (OR)
    return checkPermission.some((value) => {
      return myPermissions.indexOf(value) >= 0;
    });
  }
}
