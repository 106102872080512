import { environment } from '../../environments/environment';

export const AppConfig = {
  apiUrl: environment.apiUrl,
  version: '1.11.3',
  isTest: !environment.production && environment.isTest,
  isDemo: environment.isDemo,
  defaultStorageMode: 'local',
  googleClientId: '42648121404-1ivgbdvj19uefc9vfg94krm2pl1luun3.apps.googleusercontent.com',
  bugsnagApiKey: '03ab9fd7d9620bfae06d0f3566900aee',
};
