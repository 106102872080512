import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DmagPopAlertComponent } from './components/dmag-popalert.component';
import { AuthGuardService } from './services/auth-guard.service';
import { DmagCoreService } from './services/dmag-core.service';
import { DmagHttpInterceptor } from './services/dmag-http-interceptor';
import { DmagHttpService } from './services/dmag-http.service';
import { DmagPopAlertService } from './services/dmag-popalert.service';
import { DmagStateService } from './services/dmag-state.service';
import { DmagStorageService } from './services/dmag-storage.service';
import { PermissionGuardService } from './services/permission-guard.service';
import { PwaService } from './services/pwa-service.service';

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    HttpClientModule,
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  declarations: [DmagPopAlertComponent],
  exports: [DmagPopAlertComponent],
  providers: [
    DmagCoreService,
    DmagHttpService,
    DmagPopAlertService,
    DmagStorageService,
    DmagStateService,
    AuthGuardService,
    PermissionGuardService,
    PwaService,
    { provide: HTTP_INTERCEPTORS, useClass: DmagHttpInterceptor, multi: true },
  ],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule,
    translate: TranslateService
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }

    translate.addLangs(['it', 'en']);
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/it|en/) ? browserLang : 'en');
  }
}
